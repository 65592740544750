*,
*::after,
*::before {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  padding: 0;
  margin: 0;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  cursor: pointer;
}
button {
  cursor: pointer;
  border: none;
  background: transparent;
  transition: transform 0.3s;
}
button:active {
  transform: scale(0.95);
}

/* Desktop */
@media screen and (min-width: 1440px) {
  html {
    font-size: 10px;
  }
}
/* Laptop */
@media screen and (max-width: 1439px) {
  html {
    font-size: 0.69vw;
  }
}
:root {
  --vh: 1vh;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  overscroll-behavior-y: none;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overscroll-behavior-y: none;
}

#__next {
  overscroll-behavior-y: none;
}

.a11y-hidden {
  overflow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

@supports selector(:focus-visible) {
  /* focus-visible 지원시, focus 가상요소 선택자 스타일 제거 */
  *:focus-visible {
    outline: 2px solid #7fea69 !important;
    -webkit-box-shadow: 0px 0px 15px 5px #7fea69 !important;
    box-shadow: 0px 0px 15px 5px #7fea69 !important;
    /* border-radius: 4px; */
  }
  *:focus:not(:focus-visible) {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  /* focus-visible 가상요소 선택자로 스타일 변경 */
}

/* focus-visible 지원 안되는 브라우저 fallback 스타일 */
*:focus {
  /* border-radius: 4px; */
  outline: 2px solid #7fea69 !important;
  -webkit-box-shadow: 0px 0px 15px 5px #7fea69 !important;
  box-shadow: 0px 0px 15px 5px #7fea69 !important;
}
input:focus,
textarea:focus {
  outline: 1px solid #808080 !important;
  box-shadow: initial !important;
  -webkit-box-shadow: initial !important;
}

.bypass-block {
  top: 0;
  left: 0;
  z-index: 9999;
  position: absolute;
  transform: translateY(-200%);
  width: 100%;
  text-align: center;
  background: green;
  color: #fff;
}

.bypass-block:focus {
  transform: translateY(0);
  display: block;
}

@keyframes txtAnimation {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
